$color_1: #ffffff;
$color_2: #B8B8B8;
$color_3: #BABABA;
$color_5: #212529;
$background-color_1: #070612;
$background-color_2: #292830;
$background-color_3: transparent;
$background-color_6: #504e57;
.darkTheme{
    .table-text-ext {
        height: 1rem;
        margin: 0;
        font-size: 0.75rem;
        color: $color_1;
        @include fontStyle;
    }
    .table-data-scroll {
        display: block;
        height: 100px;
        @include overflowY;
    }
    .table-fixed {
        width: 98% !important;
        margin: 15px;
        border-radius: 15px;
        thead {
            background-color: $background-color_1;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-top: 0px;
            width: 100%;
            font-size: 0.8rem;
            display: table;
            width: 100%;
            table-layout: fixed;
            th {
                vertical-align: middle !important;
                padding: 15px 8px;
            }
        }
        tbody {
            display: table;
            width: 100%;
            table-layout: fixed;
            display: block;
            overflow: auto;
            scroll-behavior: smooth;
            table-layout: fixed;
            height: calc(100vh - 310px);
            border: 1px solid $background-color_2;
            @include devices(laptop_xl) {
                height: calc(100vh - 324px);
            } 
        }
        tr {
            display: table;
            width: 100%;
            table-layout: fixed;
        }
        td {
            border-top: none !important;
            color: $color_3;
        }
        th {
            border-top: none !important;
        }
    }
    .healthmonitoringTable {
        tbody {
            height: 215px;
            tr{   
                &:hover {
					color: $color_5;
				} 
                &:nth-of-type(odd) {
                    background-color: $background-color_2;
                }
                &:nth-of-type(even) {
                    background-color: $background-color_6;
                }
            }
        }
    }
    thead {
        th {
            p {
                margin-bottom: 0px;
                color: $color_2;
            }
        }
    }
    tr:nth-child(even) {
        background-color: $background-color_6;
    }
    .StatusSearchText {
        margin-left: 0px;
        width: 100%;
        padding-right: 10px;
        margin-top: 5px !important;
        background-color: $background-color_3;
        border-radius: 7px;
        border: 1px solid #464646;
    }
    .healthmonitoringTable{
        .SearchViewInput{
            background-color: $background-color_3;
        }
    }
    .HMTableSelect {
        height: 35px;
        width: 100%;
        border-radius: 8px;
        /* background-color: #f0f3f5; */
        border: none;
        padding-left: 8px;
        /* padding-right: 8px; */
        font-size: 13px;
        text-align: left;
        background-position: calc(100% - 10px) 16px, calc(100% - 10px) 15px, 100% 0 !important;
        margin-bottom: 0px;
        background-color: $background-color_1;
       
    }:focus-visible{
        outline: none;
    }
    .OnHoverMouseTableRow .no-report-found {
        height: 210px;
    }
}