$color_1: #0b70d1;
$color_2: #BFBFBF;
$color_3: #fff;
$color_4: #fff;
$font-family_1: Segoe UI;
$background-color_1: #0B70D1;


.darkTheme{
    .NotificationDrawer {
        .rs-drawer-header {
            .rs-drawer-header-close {
                right: 3px;
                left: unset;
                top: 5px;
                color: $color_1;
            }
            padding: 10px;
            border-bottom: 0px;
        }
        .rs-drawer-title {
            font-weight: 600;
        }
        .rs-drawer-body {
            padding: 0px;
            height: calc(100% - 40px) !important;
        }
        .rs-drawer-content {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            background-color:#070612;
        }
        p {
            margin: 0;
        }
    }
    .NotificationDrawer.rs-drawer-right {
        height: calc(100% - 90px);
        margin-top: 70px;
        width: 480px !important;
    }
    .NotificationDrawer.rs-drawer {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
    }
    .NotificationListItem {
        padding: 10px;
        border-radius: 5px;
        box-shadow: 2px 2px 7px #00000033;
        margin-bottom: 15px;
        background-color: #1F1A24;
    }
    .ItemOnlineStatus {
        display: flex;
        justify-content: space-between;
    }
    .NotificationTextDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .CameraStatusNameText {
        font-size: 14px;
        font-weight: 500;
        font-family: $font-family_1;
        color: $color_2;
    }
    .AlertImgBg {
        border-radius: 5px;
        height: 40px;
        width: 40px;
        float: left;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    .AlertImgIcon {
        height: 22px;
        width: 22px;
    }
    .DateTimeText {
        font-size: 12px;
        letter-spacing: 0px;
        font-weight: 500;
        color: $color_3;
    }
    .CameraStatusDetailsP {
        font-size: 12px;
        word-break: break-word;
        overflow: hidden;
        color: $color_3;
    }
    .CameraStatusNotifyDiv {
        width: 95%;
        margin-left: 10px;
    }
    .MarkStatusDiv {
        height: 8px;
        width: 8px;
        background-color: $background-color_1;
        border-radius: 10px;
    }
    .Not_Header {
        display: flex;
        justify-content: space-between;
        width: 90%;
        p {
            font-family: $font-family_1;
            font-size: 15px;
            font-weight: 600;
            line-height: 1.33;
            letter-spacing: 0.02px;
            text-align: left;
            color: $color_4;
        }
    }
    .NotificationListHgtDiv {
        height: 99%;
        overflow: auto;
        padding: 10px;
    }
    .unread {
        background: #f2f6fe;
    }
    .MoreBtn{
        background: transparent;
        color: $color_4;
        text-decoration: underline;
    }
    .notification-tray {
		width: 20rem;
		height: 32.813rem;
		padding: 0 0 1.063rem !important;
		border-radius: 12px !important;
		box-shadow: 0 3px 6px 0 #00000029;
		background-color: #fff;
		margin: 1rem !important;
		top: 10% !important;
		right: 0% !important;
	}

	.notification-header {
		/* width: 17.75rem; */
		height: 3.125rem;
		/* margin: 0 0 0.625rem; */
		padding: 0.938rem 0.625rem;
		border: solid 1px #0000;
		border-radius: 12px 12px 0 0;
		/* background-image: linear-gradient(252deg, #4ca0c2 98%, #027aaa); */
		background-image: linear-gradient(252deg, #4ca0c2 98%, #4ca0c2);
	}

	.notification-header span:first-child {
		width: 5.5rem;
		height: 1.25rem;
		margin: 0 9.75rem 0 0;
		font-size: 0.938rem;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
	}

	.n-count {
		border-radius: 99px;
	}

	.n-text {
		/* width: 0.875rem; */
		height: 1rem;
		font-size: 0.75rem;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #131415;
		float: right;
	}

	.n-tray-list {
		padding: 0.625rem;
		list-style: none;
		height: 91%;
		overflow-y: scroll;
		scroll-behavior: smooth;
		overflow-x: hidden;
	}

	.n-tray-item {
		/* width: 16.5rem; */
		/* height: 3.313rem; */
		margin: 0 0 0.625rem;
		padding: 0.35rem;
		border-radius: 13px;
		border: solid 0.5px #1ba9d6;
		background-color: #fff;
	}

	.n-tray-desc {
		width: 12.813rem;
		height: 1rem;
		margin: 0 0 0 0.625rem;
		font-size: 0.75rem;
		font-weight: 300;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #182e37;
	}

	.oval {
		width: 0.25rem;
		height: 0.25rem;
		margin: 0.375rem 0.313rem;
		border-radius: 99px;
		background-color: #a2a4b3;
	} 
    .SecondDivSearch {
        display: flex;
        align-content: center;
        justify-content: space-between;
        align-items: center;
    } 
    .notification-category-mask {
        width: 99.7%;
        /* height: 77vh; */
        margin: 1rem auto;
        margin-left: 1rem;
        /* opacity: 0.67; */
        border-radius: 12px;
        background-color: #fff;
    }

    .header-notification-type {
        /* width: 17.75rem; */
        height: 3.625rem;
        /* margin: 0 0 1.125rem; */
        /* padding: 1.188rem 0.625rem 1.125rem 0.625rem; */
        border-radius: 12px 12px 0 0;
        background-image: linear-gradient(84deg, #f80 -28%, #e65003 102%);
    }

    .icon-header {
        width: 4rem;
        /* height: 1rem; */
        margin: 0.125rem 0.375rem 0.187rem 0;
        object-fit: contain;
    }

    .header-notification {
        width: 2.625rem;
        height: 1.25rem;
        margin: auto 0;
        /* font-size: 0.938rem; */
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }

    .badge-notification {
        width: 1.25rem;
        height: 1.25rem;
        /* margin: 0 0 0.063rem 1rem; */
        padding: 0.125rem 0.375rem;
        background-color: #fff;
    }

    .filter-ext {
        /* width: 4.563rem; */
        height: 1rem;
        margin: 1.12rem 0.309rem 1.12rem 0.375rem;
        font-size: 0.75rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #4a595f;
    }

    .notification-list {
        /* width: 17.75rem; */
        height: 60vh;
        overflow-y: scroll;
        scroll-behavior: smooth;
        margin: 0.243rem 0 0;
        padding: 0.875rem 0 0 0.625rem;
        /* opacity: 0.67; */
        border-radius: 12px;
        background-color: #fff;
    }

    .notification-list-item {
        width: 18rem;
        /* height: 4.313rem; */
        margin: 0 0 0.625rem;
        padding: 0.313rem 0.635rem 0.563rem 0.313rem;
        border-radius: 13px;
        border: solid 0.5px #1ba9d6;
        background-color: #fff;
        list-style: none;
    }

    .notification-read {
        width: 0.438rem;
        height: 0.438rem;
        /* margin: 0 0 0.188rem; */
        border-radius: 99px;
        background-color: #ff8006;
    }

    .notification-desc {
        width: 11.75rem;
        height: 2rem;
        margin: 0.375rem 1.095rem 0.063rem 0rem;
        font-size: 0.75rem;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #182e37;
    }

    .notification-time {
        /* width: 3.25rem; */
        height: 1rem;
        margin: 0 0 0 0.313rem;
        font-size: 0.75rem;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #a2a4b3;
    }

    .mark-unread-text {
        width: 6.125rem;
        height: 1.188rem;
        font-size: 0.875rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #4e4e4e;
    }
    .notification-options {
        display: inline-flex !important;
        cursor: pointer;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding: 0 0.5rem !important;
    }
    .badge {
        position: absolute;
        top: 0.7rem;
        /* right: 13.5rem; */
    }
    .notification-link {
        font-weight: 500;
    }
    .notification-link:hover {
        color: #006994;
        text-decoration: underline;
    }
    .dropdown-item {
        text-decoration: none !important;
    }

}