$color_1: #83ba2b;
$color_2: #F8830D;
$color_3: #ACACAC;


.lightTheme{
    .downloadTable tbody{
        @include devices(laptop_xxl) {
            width: 87% !important;
        } 
        @include devices(laptop_xl) {
            height: calc(100vh - 305px);
        }
        @include devices(laptop_lg) {
            height: calc(100vh - 300px);
        } 
        @include devices(laptop_md) {
            height: calc(100vh - 300px);
        }
        @include devices(laptop_sm) {
            height: calc(100vh - 300px);
        }
    }
    .downloadDetailTable{
        .StatusSearchText {
            width: 100%;
            @include devices(laptop_xxl) {
                width: 90%;
            } 
            @include devices(laptop_xl) {
                width: 89%;
            }
            @include devices(laptop_lg) {
                width: 85%;
            } 
            @include devices(laptop_md) {
                width: 85%;
            } 
        }
        .SearchViewInput {
            width: 89%;
            @include devices(laptop_xxl) {
                width: 87% !important;
            } 
            @include devices(laptop_xl) {
                width: 87% !important;
            }
            @include devices(laptop_lg) {
                width: 83% !important;
            } 
            @include devices(laptop_md) {
                width: 83% !important;
            }
            @include devices(laptop_sm) {
                width: 82% !important;
            }
        }
        p {
            @include devices(laptop_sm) {
                font-size: 12px;
            }
        }
        select {
            padding-left: 0px;
            @include devices(laptop_xl) {
                background-position: calc(100% - 2px) 16px, calc(100% - 10px) 15px, 100% 0 !important;
            }
            @include devices(laptop_lg) {
                background-position: calc(100% - 2px) 16px, calc(100% - 10px) 15px, 100% 0 !important;
            } 
            @include devices(laptop_md) {
                font-size: 11px;
                background-position: calc(100% - 2px) 16px, calc(100% - 10px) 15px, 100% 0 !important;
            }
            @include devices(laptop_sm) {
                font-size: 10px;
                background-position: calc(100% - 2px) 16px, calc(100% - 10px) 15px, 100% 0 !important;
            }
            option {
                padding-left: 8px !important;
                @include devices(laptop_sm) {
                    font-size: 12px;
                }
            }
        }
    }    
    .Low {
        color: $color_1 ;
    }
    .inprogress{
        color: $color_2;
    }
    .linkdisabled{
        color: $color_3;
        cursor: no-drop;
    }

}