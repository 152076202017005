$color_1: #fff;
$color_2: white;
$font-family_1: Segoe UI;
$background-color_1: #fff;
$background-color_2: unset;
$background-color_3: lightgray;
$background-color_4: transparent;
$background-color_5: #0b70d1;
$background-color_6: #006edc;

.lightTheme{
        .ORLine {
            position: relative;
            height: 1px;
            width: 100%;
            margin: 20px 0px;
            background-color: #d4d4d4;
            // background-color: #dee2e6;
            &::before {
                content: "OR";
                position: absolute;
                top: 50%;
                right: 35%;
                transform: translate(-50%, -50%);
                background-color: #fff;
                color: #2F2F2F;
                padding: 0px 15px;
            }
        }
        .popupDiv {
            width: 296px;
            height: 398px;
            margin-top: 1px;
            margin-left: 1px;
            border: 0px;
            background-color: $background-color_1;
        }
        .popup_card {
            width: 100%;
            margin: 0 auto;
            border: 0px solid lightgray;
            border-radius: 0px !important;
            overflow: hidden;
            -moz-border-radius: 0.5rem;
            -webkit-border-radius: 0.5rem;
        }
        .popup-content {
            padding: 0px;
        }
        .react-calendar {
            border: 0px;
            padding: 15px;
            font-size: 12px;
            font-family: $font-family_1;
        }
        .react-calendar__navigation__arrow {
            font-size: 15px;
            font-size: 25px !important;
        }
        .react-calendar__navigation {
            @include display-flex ;
            height: auto;
            margin-bottom: 1em;
            button {
                background-color: $background-color_2 !important;
            }
        }
        .react-calendar__tile--now {
            background: #7eb1e3;
            color: $color_1;
            border-radius: 20px;
            &:enabled {
                &:hover {
                    background: $background-color_6;
                }
                &:focus {
                    background: $background-color_6;
                }
            }
        }
        .popup_header {
            margin-bottom: 0rem;
            text-align: center;
            background-color: $background-color_3;
            border-radius: 0px;
            height: 30px;
        }
        .react-calendar__tile {
            &:disabled {
                background-color: $background-color_4;
            }
        }
        .react-calendar__tile--active {
            background: $background-color_6;
            color: $color_2;
            border-radius: 20px;
        }
        .react-calendar__year-view {
            .react-calendar__tile {
                padding: 1.5em 0.5em;
            }
        }
        .react-calendar__decade-view {
            .react-calendar__tile {
                padding: 1.5em 0.5em;
            }
        }
        .react-calendar__century-view {
            .react-calendar__tile {
                padding: 1.5em 0.5em;
            }
        }
        .popup_cardTimer {
            margin-top: 1rem;
            @include display-flex ;
            width: 100%;
            justify-content: center;
        }
        .popup_cardTimerRight {
            border: 1px solid lightgray;
            border-radius: 7px;
            @include flexCenter;
            padding: 0.1rem;
            font-size: 0.9rem;
            margin-right: 6px;
        }
        .popup_cardTimerRightArrow {
            margin-top: 0.5rem;
        }
        .popup_cardTimerRightTwo {
            border: 1px solid lightgray;
            border-radius: 7px;
            @include flexCenter;
            font-size: 0.8rem;
            padding-top: 0.5rem;
            width: 6rem;
            margin-left: 6px;
            >p {
                margin-bottom: 0.5rem;
            }
        }
        .popup_link {
            display: block;
            text-align: center;
            padding: 0.2rem;
            border-radius: 0.5rem;
            width: 100%;
            // margin-left: 2%;
            margin-top: 1rem;
            color: $color_2;
            background-color: $background-color_5;
            cursor: pointer;
        }
        abbr[data-original-title] {
            text-decoration: none !important;
        }
        abbr[title] {
            text-decoration: none !important;
        }        
        .timerDiv__input {
            width: 2rem;
            padding: 0.3rem;
            border: none;
            background-color: transparent;
        }        
        .timerDiv {
            display: flex;
            flex-direction: row;
        }  
        .timerDiv_Controls {
            display: flex;
            flex-direction: column;
        }
        .timerDiv__btn {
            background-color: #367CB9;
            color: $color_1;
        }
        .timerWidgets {
            display: flex;
            flex-direction: row;
        }
        .popup-content {
            width: 330px !important;
            z-index: 1 !important;
            padding: 1rem; 
        }
        .popup_body {
            flex-direction: row;
            text-align: center;
            @include flexCenter;
            margin: 0.2rem;
            gap: 0.5rem;
        }
}