

$color_1: #B8B8B8;
$color_2: #131415;
$color_3: #212529;
$color_4: #748b94;
$color_5: #D9D9D9;
$color_6: #BABABA;
$background-color_1: #1F1A24;
$background-color_2: #FFFFFF;
$background-color_3: #fffefb;
$background-color_4: #fff;
$background-color_5: unset;
$background-color_6: #504e57;
$background-color_7: #070612;
$background-color_8: #748b94;
$background-color_9: #0b70d1;
$background-color_10: #53a3c5;
$background-color_11: #484848;
$size: 70px;
$box-shadow_1:rgb(112 111 111) 0px 0px 5px 0px inset, 0 0 6px 0 rgba(0, 0, 0, 0.3);

.darkTheme{
	.MainContentMargin {
		margin: 0px 10px;
		height: 100%;
	}
	.dashboardHeadingInnerDiv {
		margin-top: 15px;
		margin-bottom: 15px;
		height: 32px;
	}
	.dashboardScroll {   
		@include devices(laptop_xxl) {
			height: 750px;
			overflow-y: auto;
			overflow-x: hidden;
		} 
		@include devices(laptop_xl) {
			height: 730px;
			overflow-y: auto;
			overflow-x: hidden;
		} 
		@include devices(laptop_lg) {
			height: 640px;
			overflow-y: auto;
			overflow-x: hidden;
			padding-top: 10px;
		}  		
		@include devices(laptop_md) {
			height: 655px;    
		}  
		@include devices(laptop_sm) {
			height: 532px;    
		}		
    }
	.dashboardheadingMain {
		width: 100%;
		@include floatLeft;
		margin-bottom: 0px;
	}
	.dashboardheading {
		@include floatLeft;
		p {
			font-size: 1.5rem;
			@include fontStyle;
			color: $color_5;
			margin-bottom: 0;
			span {
				font-size: 1.5rem;
				font-weight: bold;
			}
		}
	}
	.RowClass {
		width: 100%;
		float: left;
		padding: 10px 0px;
		margin: 0;
	}
	.grid-container--fit {
		grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	}
	.grid-container {
		display: grid;
	}
	.KPI_Margin {
		margin: 0px 10px;
	}
	.topKpiCard {
		height: 7rem;
		border-radius: 13px;
		cursor: pointer;
		box-shadow: $box-shadow_1;
		margin: 0px 0px 20px 0px;
		background-color: $background-color_1;
	}
	.AlertsCount {
		display: inline-flex;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
	.dashboard-card-content {
		margin: 1.8rem;
		height: auto;
		@include display-flex;
	}
	.dashboard-card-image {
		width: 3rem;
		height: 3rem;
		opacity: 0.8;
		border-radius: 9px;
		background-color: $background-color_2;
		text-align: center;
		@include flexCenter;
	}
	.dashboard-icon {
		height: 1.5rem;
	}
	.dashbord-card-txt {
		margin-left: 20px;
		height: 3rem;
	}
	.active-sub-menu span {
		font-weight: 600;
		color: $color_6;
		font-size: 14px;
	}
	.NumberText {
		font-size: 21px;
		line-height: 23px;
		font-weight: bold;
		color: $color_6;
		@include ValueText-Style;
	}
	.TextValue {
		font-size: 17px;
		line-height: 15px;
		font-weight: normal;
		margin-left: 0.2rem;
		color: $color_1;
		@include ValueText-Style;
	}
	.bottomKpiCard {
		height: 100%;
		padding: 0 0 0 1.188rem;
		border-radius: 13px;
		box-shadow: $box-shadow_1;
		background-color: $background-color_1;
	}
	.kpi2 {
		width: 100%;
		@include flexSpaceBetween;
	}
	.smallKpiText {
		p {
			margin-bottom: 2px;
			&:first-child {
				font-size: 1.25rem;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: left;
				color: $color_6;
				white-space: nowrap;
				width: 10rem;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&:last-child {
				font-size: 0.75rem;
				font-weight: 300;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: left;
				color: $color_1;
			}
		}
	}
	.ovalDiv {
		background: #27232B;
		height: 75px;
		width: $size;
		border-bottom-left-radius: $size * 2;
		border-top-left-radius: $size * 2;
		border-top-right-radius: 13px;
		border-bottom-right-radius: 13px;
		@include flexCenter;

	}
	.WrapperTable {
		display: grid;
		grid-template-columns: 74% 25.5%;
		grid-gap: 1em;
		min-height: 570px;
	}
	.alertTable {
		display: inline-block;
		width: 100%;
		border-radius: 12px;
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
		background-color: $background-color_1;
		padding: 1.156rem 0 1.031rem 0;
		height: 100%;
	}
	.alertTableList {
		@include floatLeft;
		width: 100%;
		padding: 0 1.594rem 0 1.25rem;
		p {
			@include floatLeft;
			color: $color_6;
		}
	}
	.removeClickStyle {
		width: 98% !important;
		margin-bottom: 1rem;
		margin: 12px;
		height: 100%;
	}
	.sticky-table {
		tbody {
			display: block;
			overflow: auto;
			font-size: 15px;
			border: 1px solid #292830;
			tr {
				display: table;
				width: 100%;
				table-layout: fixed;
				border-bottom: 0;
				&:hover {
					color: $color_3;
				}
				td {
					vertical-align: middle !important;
					border-top: none !important;
					border-bottom: none;
					word-break: break-word;
					padding: 5px 10px;
					&:first-child {
						padding-left: 20px;
						img {
							padding-right: 8px;
						}
					}
					&:last-child {
						img {
							padding-right: 20px;
						}
					}
				}
				&:active {
					color: $color_3;
				}
				&:nth-of-type(odd) {
					background-color: $background-color_1 !important;
				}
				&:nth-of-type(even) {
					background-color: $background-color_6;
				}
			}
		}
		thead {
			td {
				font-weight: 600;
				border-bottom: 1px solid #070612;
				vertical-align: middle;
			}
			display: table;
			width: 100%;
			table-layout: fixed;
			background-color: $background-color_7;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			border-top: 0px;
			width: 100%;
			font-weight: 500;
			height: 55px;
			tr {
				td {
					&:last-child {
						border-right: none !important;
					}
				}
			}
		}
		td {
			font-size: 13px;
			@include fontStyle;
			color: $color_1;
			padding: 10px 10px;
			border-top: 0px !important;
			background-color: $background-color_5 !important;
			&:first-child {
				padding-left: 20px;
			}
		}
	}
	.tbody-dashboard {
		height: calc(100% - 90px) !important;
	}

	body {
		&::-webkit-scrollbar {
			width: 8px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: $background-color_8;
			border-radius: 100px;
		}
	}
	.sticky-table tbody{
		&::-webkit-scrollbar {
			width: 5px;
			height: 5px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: $background-color_8;
			border-radius: 100px;
		}
	}

	.kpi-card {
		padding: 10px;
		border-radius: 8px;
		box-shadow: $box-shadow_1;
		background-color: $background-color_1;
	}
	.section-head {
		display: inline-flex;
		width: 17.75rem;
		padding: 10px;
		margin-bottom: 0.2rem;
		.location-alerts {
			width: 12.625rem;
			height: 1.438rem;
			font-size: 1.0rem;
			color: $color_6;
			@include fontStyle;
		}
	}
	.top-three-sites {
		width: 84px !important;
	}
	.bottom-three-sites {
		width: 100px !important;
	}
	.tabs-list {
		width: 100%;
		height: 2.3rem;
		padding: 0.18rem 5px 0 0px;
		border-radius: 18px;
		box-shadow: 0 3px 15px 0 #00000014;
		border: solid 1px #4d4e4e;
		background-color: $background-color_1;
		text-align: center;
	}
	.nav-link {
		/* width: 100%; */
		height: 1rem;
		margin: 0.438rem 0 0.375rem 1.313rem;
		font-size: 0.75rem;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: $color_4;
		margin: 0;
		height: auto;
	}
	.nav-item {
		>.active {
			width: 100%;
			height: 1.813rem;
			margin-left: 0.2rem;
			padding: 0.4rem 0.2rem;
			border-radius: 15.5px !important;
			background-color: $background-color_9 !important;
			margin-top: 0.01rem;
		}
	}
	.nav-pills {
		.nav-link.active{
			// color: $color_6;
			color: #dddddd;
		}
	}
	.location-list-item {
		width: 100%;
		height: 2.625rem;
		margin: 0.5rem 0;
		padding: 0.5rem 0.5rem;
		border-radius: 8px;
		border: 0;
		background-color: $background-color_7;
		&::before {
			content: "";
			width: 8px;
			height: 8px;
			border-radius: 50%;
			margin-right: 5px;
			display: inline-block;
			background-color: $background-color_10;
			vertical-align: middle;
		}
	}
	.qc-actions {
		@include floatRight;
		padding: 0 0 0 0.6rem;
		>img {
			padding: 0 0 0 0.8rem;
		}
	}
	.manage-config {
		margin: 1rem;
		padding: 0.3rem 1rem;
		border-radius: 15.5px !important;
		background-image: linear-gradient(244deg, #0f62acc9 26%, #0f62ac);
		border: none;
		align-self: center;
		&:focus {
			outline: none;
		}
		>span {
			width: 9.25rem;
			height: 1.188rem;
			font-size: 0.875rem;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: $color_1;
		}
	}
	.manage-config-align {
		@include flexCenter;
	}
	.inactive-text-dashboard {
		width: 100px !important;
		text-align: center;
		font-size: 0.75rem;
		font-weight: normal;
		color: $color_4;
		display: inline-block;
		margin-left: 8px;
		cursor: pointer;
		text-decoration: none;
		&:hover {
			text-decoration: none;
			color: $color_4;
		}
	}
	.template-list {
		height: 170px;
		overflow-y: scroll;
		scroll-behavior: smooth;

		&::-webkit-scrollbar {
			width: 5px;
			height: 5px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: $background-color_8;
			border-radius: 100px;
		}
	}
	.noRecordFound {
		position: relative;
		height: 100%;
	}
	.noRecordFoundText {
		position: absolute;
		top: 50%;
		left: 46%;
		font-size: 0.9rem;
		color: $color_1;
	}
	.dashboard-quickconfig-alerttemplate-outer {
		@include display-flex;
		box-shadow: 0 3px 15px 0 #00000014;
		border: solid 1px #4d4e4e;
		border-radius: 18px;
		width: 100%;
	}
	.dashboard-quickconfig-alerttemplate-inner {
		height: 30px;
		padding: 5px;
		margin: 3px;
		border-radius: 15.5px;
		background-color: $background-color_9;
	}
	.dashboard-quickconfig-alerttemplate-text {
		// color: $color_1;
		color: #dddddd;
		font-size: small;
		padding: 0rem 0.5rem;
	}
    .systemHealth {
        box-shadow: $box-shadow_1;
        background-color: $background-color_1;
        height: 100%;
        border-radius: 13px;
        margin: 0px 10px;
        @include floatLeft;
    }
    .statusValueText {
        color:  $color_1;
    }
    .statusValueP {
        color: $color_1;
    }
	.viewAllListBtn {
		@include floatRight;
		padding: 0.219rem 0.781rem 0.156rem 0.844rem;
		border-radius: 12px;
		background-color: #0b70d1;
		font-size: 0.875rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1;
		letter-spacing: normal;
		text-align: right;
		color: #027aaa;
		height: 32px;
		p {
			// color: $color_1;
			color: #dddddd;
			font-size: small;
			padding: 0rem 0.5rem;
			margin-bottom: 0px !important;
		}
	}
	.card-body {
		padding: unset;
		padding-top: 20px;
	}
	
	.template-name {
		width: 70%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.template-list {
		height: 174px;
		overflow-y: scroll;
		scroll-behavior: smooth;
	}

	.dashboard_configuration_templateName {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		width: 75%;
		font-size: 0.75rem;
		color: $color_1;
	}

	.template-list-item {
		display: inline-flex;
		align-content: stretch;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
	}

	.pointerItem {
		cursor: pointer;
	}
	.DayCount {
		height: 40px;
		@include flexSpaceBetween;
		margin-left: 15px;
		margin-right: 15px;
	}
	
	.DayText {
		font-size: 0.75rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: 0.75px;
		text-align: center;
		color: #182e37;
	}
	
	.today {
		height: 25px;
		width: 25px;
		border-radius: 10px;
		background-color: #fff;
		color: #000;
		padding: 0.3rem;
		box-shadow: 0 0 11px rgba(33, 33, 33, .2);
		border-radius: 50%;
		display: inline-block;
	}
	.AlertKPIBG {
		background-color: #DA8012;
	}
	
	.SiteKPIBG {
		background-color: #6459D6;
	}
	
	.CameraKPIBG {
		background-color: #27AD93;
	}
	
	.OperatorKPIBG {
		background-color: #1D8CDB;
	}		
	/* dashboard kpi css start */
	.SeverityKpiBG {
		background-color: #27AD93
	}
	.SitesKpiBG {
		background-color: #6459D6;
	}
	.KpiUpperDiv {
		@include display-flex;
		justify-content: space-between;
	}
	.DashboardKpiNumber {
		font-size: 1.125rem;
		font-weight: bold;
		font-stretch: normal;
		color: #fff;
		margin: 0px;
		@include line-height;
		/* margin-left: 0.2rem; */
	}
	.DashboardKpiText {
		font-size: 1.2rem;
		color: #fff;
		text-shadow: 0 2px 20px #f9c44c;
		margin: 0px;
		@include fontStyle;
	}
	.kpiTextValue {
		/* font-size: 1.5rem; */
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
		text-shadow: 0 2px 20px #f9c44c;
		margin: 0px;
	}
	.KpiNumberValue {
		/* ont-size: 1.125rem; */
		font-weight: bold;
		font-stretch: normal;
		color: #fff;
		margin: 0px;
		@include line-height;
		/* margin-left: 0.2rem; */
	}
	.StatusInActive {
		@include statusStyle;
		background-color: red;
		border: 2px solid white;
	}
	.StatusActive {
		@include statusStyle;
		background-color: green;
		border: 2px solid white;
	}
	/* dashboard kpi changes css end */
		
	.StatusHeaderDiv{
		min-width: 67vw;
		@include flexSpaceBetween;
	}
	.StatusTableDiv{
		overflow: auto;
		height: 70vh;
	}
	.StatusTableDiv thead{
		background-color: #dbe6fc;
		height: 85px; 
		position: sticky; 
		top: 0; 
		z-index: 1;
	}
	.StatusTableDiv thead th{
		text-align: left;
		color: #535353;
		font-size: 15px;
		border-top: none;
		width: 12vw;
	}
	.StatusTableDiv thead th p{
		margin-bottom: 0px !important;
	}
	.StatusTableDiv thead th:first-child{  
		border-top-left-radius: 5px;
	}
	.StatusTableDiv thead th:last-child{  
		border-top-right-radius: 5px;
	}
	.StatusTableDiv th, td {
		text-align: left;
	}
	.StatusTableDiv td{
		font-size: 0.8vw;
		padding:  0.3vw 0.3vw 0.3vw 1vw !important;
	}  
	.StatusSearchText .SearchViewInput{
		width: 93%;
	}
	.DateTimeTableSpan{
		margin-right: 3vw;
	}
	.tbody-dashboard tr:hover td {
		background-color: $background-color_11 !important;
	}
}